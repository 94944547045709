import React, { useState, useEffect, useCallback } from "react"
import { PageProps } from "gatsby"
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { Input, Row, Col } from "antd"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AppointmentBooking from "../components/appointment/booking"
import awsconfig from "../aws-exports"
Amplify.configure(awsconfig)

const NOTSIGNIN = "You are NOT logged in"
const SIGNEDIN = "You have logged in successfully"
const SIGNEDOUT = "You have logged out successfully"
const WAITINGFOROTP = "Enter OTP number"
const VERIFYNUMBER = "Verifying number (Country code +XX needed)"

const Booking: React.FC<PageProps> = () => {
  const [message, setMessage] = useState("Welcome to Demo")
  const [user, setUser] = useState(null)
  const [session, setSession] = useState(null)
  const [otp, setOtp] = useState("")
  const [number, setNumber] = useState("")
  const password = Math.random().toString(10) + "Abc#"

  useEffect(() => {
    verifyAuth()
  }, [])

  const verifyAuth = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUser(user)
        setMessage(SIGNEDIN)
        setSession(null)
      })
      .catch(err => {
        console.error(err)
        setMessage(NOTSIGNIN)
      })
  }

  const signOut = () => {
    if (user) {
      Auth.signOut()
      setUser(null)
      setOtp("")
      setMessage(SIGNEDOUT)
    } else {
      setMessage(NOTSIGNIN)
    }
  }

  const signIn = () => {
    setMessage(VERIFYNUMBER)

    Auth.signIn(number)
      .then(result => {
        setSession(result)
        setMessage(WAITINGFOROTP)
      })
      .catch(e => {
        if (e.code === "UserNotFoundException") {
          signUp()
        } else if (e.code === "UsernameExistsException") {
          setMessage(WAITINGFOROTP)
          signIn()
        } else {
          console.log(e.code)
          console.error(e)
        }
      })
  }

  const signUp = async () => {
    const result = await Auth.signUp({
      username: number,
      password,
      attributes: {
        phone_number: number,
      },
    }).then(() => signIn())
    console.log(result)
    return result
  }

  const verifyOtp = () => {
    Auth.sendCustomChallengeAnswer(session, otp)
      .then(user => {
        setUser(user)
        setMessage(SIGNEDIN)
        setSession(null)
      })
      .catch(err => {
        setMessage(err.message)
        setOtp("")
        console.log(err)
      })
  }

  const handleOnChange = useCallback(v => {
    console.log(v.currentTarget.value)
    setNumber(v.currentTarget.value)
  }, [])

  return (
    <Layout>
      <SEO title="Booking" />

      {/* <p>{message}</p>

      {!user && !session && (
        <div>
          <Input placeholder="Basic usage" onChange={handleOnChange} />
          <button onClick={signIn}>Signin</button>
        </div>
      )}

      {!user && session && (
        <div>
          <Input
            placeholder="Your OTP"
            onChange={event => setOtp(event.target.value)}
            value={otp}
          />
          <button onClick={verifyOtp}>Confirm</button>
        </div>
      )}
      <div>
        <button onClick={verifyAuth}>Am I sign in?</button>
        <button onClick={signOut}>Sign Out</button>
      </div> */}

      <br />
      <Row>
        <Col span="24">
          <AppointmentBooking />
        </Col>
      </Row>
    </Layout>
  )
}
export default Booking
