/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:214a197b-4807-4e9d-8771-500867d99d90",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_AKxliz2LA",
    "aws_user_pools_web_client_id": "29amnl3topkicsgjd1ei9v29qi",
    "oauth": {}
};


export default awsmobile;
